import React from "react";

const BannerHead = () => {
  return (
    <div className="banner-head text-center w-full bg-black text-white text-5xl py-3">
      RICHFEYN
    </div>
  );
};

export default BannerHead;
