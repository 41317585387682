import React from 'react'

const Ai = () => {

    return (
        <div className="lg:mt-10 xl:mt-10 2xl:mt-10 mx-auto items-center">
        <h2 className="font-semibold mb-4 text-center">See demo video</h2>
            <video  className=" mb-4 w-[50vw] md:w-[60vw] max-w-[700px]  max-h-[400px] min-w-[150px] min-h-[150px] mx-auto"  controls autoPlay muted>
                <source src="/Richfeyn_AI_demo.mp4" type="video/mp4" />
            </video>
        <h2 className="font-semibold mb-4 text-center">Transforming mannequin faces into lifelike expressions</h2>
        <div className='flex flex-row justify-center mx-auto'>
        <img
                loading="lazy"
                className="w-[45vw] md:w-[50vw] max-w-[400px] max-h-[350px] min-w-[150px] min-h-[150px]"
                src="/images/M1.jpg"
                alt=""
            />
            <img
                loading="lazy"
                className="w-[45vw] md:w-[50vw] max-w-[400px] max-h-[350px] min-w-[150px] min-h-[150px] "
                src="/images/Man1.jpg"
                alt=""
            />
        </div>

        <div className='flex flex-row justify-center mx-auto'>
        <img
                loading="lazy"
                className="w-[45vw] md:w-[50vw] max-w-[400px] max-h-[350px] min-w-[150px] min-h-[150px]"
                src="/images/M1.jpg"
                alt=""
            />
            <img
                loading="lazy"
                className="w-[45vw] md:w-[50vw] max-w-[400px] max-h-[350px] min-w-[150px] min-h-[150px] "
                src="/images/Man2.jpg"
                alt=""
            />
        </div>

        <div className='flex flex-row justify-center mx-auto'>
        <img
                loading="lazy"
                className="w-[45vw] md:w-[50vw] max-w-[400px] max-h-[350px] min-w-[150px] min-h-[150px]"
                src="/images/M1.jpg"
                alt=""
            />
            <img
                loading="lazy"
                className="w-[45vw] md:w-[50vw] max-w-[400px] max-h-[350px] min-w-[150px] min-h-[150px] "
                src="/images/Man3.jpg"
                alt=""
            />
        </div>
        <div className='flex flex-row justify-center mx-auto'>
        <img
                loading="lazy"
                className="w-[45vw] md:w-[50vw] max-w-[400px] max-h-[350px] min-w-[150px] min-h-[150px]"
                src="/images/M1.jpg"
                alt=""
            />
            <img
                loading="lazy"
                className="w-[45vw] md:w-[50vw] max-w-[400px] max-h-[350px] min-w-[150px] min-h-[150px] "
                src="/images/Man4.jpg"
                alt=""
            />
        </div>
        

            {/* <img
                loading="lazy"
                className="w-[55vw] md:w-[60vw] max-w-[700px] max-h-[400px] min-w-[150px] min-h-[150px] mx-auto"
                src="/images/change.png"
                alt=""
            /> */}
        </div>
    );
};

export default Ai;
